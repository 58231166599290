import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { ProjectSummary } from "../../components/projects/ProjectSummary";

const BrooksideSubdivision = () => (
  <Layout>
    <SEO
      title="Criterion Lane Car Park"
      image={"/asphalt.jpg"}
      description="Construction of a new car parking area at Criterion Lane and associated stormwater drainage works."
    />

    <div className="row g-0">
      <div className="col-lg-6">
        <div className="content-box">
          <h1 className="section-heading section-heading--left">
            Criterion Lane Car Park
          </h1>
          <ProjectSummary
            name="Construction of Criterion Lane Carpark"
            type="Car Park Construction"
            location="Upper Hutt, Wellington"
            client="Upper Hutt City Council"
            engineer="Upper Hutt City Council"
          />
          <p>
            Construction of a new car parking area at Criterion Lane and
            associated stormwater drainage works. The scope included; bulk
            earthworks, construction of kerbing, installation of new sumps, sump
            leads and dish channels, installation of soak pits, laying of the
            sub-base, base course and asphaltic surface, construction of garden
            beds, concrete footpaths, and installation of underground ducting
            and infrastructure.
          </p>
        </div>
      </div>
      <div className="col-lg-6 d-flex align-items-center justify-content-center">
        <StaticImage
          // aspectRatio={16 / 11}
          // transformOptions={{ cropFocus: "center" }}
          // objectPosition="50% 50%"
          src="../../images/asphalt.jpg"
          alt="Asphalt"
        />
      </div>
    </div>
  </Layout>
);

export default BrooksideSubdivision;
